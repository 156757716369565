import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dj-equipment',
  templateUrl: './dj-equipment.component.html',
  styleUrls: ['./dj-equipment.component.css']
})
export class DjEquipmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
