import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Retros-Website';

  loadedFeature = "";
  onNavigate(feature:string){
    console.log(feature)
    this.loadedFeature = feature;
  }


  onActivate(event) {
    window.scroll(0,0);
    
  }


}
