import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { Ps2Component } from './database/ps2/ps2.component';
// import { WiiComponent } from './database/wii/wii.component';
// import { Ps1Component } from './database/ps1/ps1.component';
// import { GcnComponent } from './database/gcn/gcn.component';

// import { N64Component } from './database/n64/n64.component';
// import { SegacdComponent } from './database/segacd/segacd.component';
// import { SegasaturnComponent } from './database/segasaturn/segasaturn.component';
// import { DSComponent } from './database/ds/ds.component';

import {HomeComponent} from './home/home.component'
import {AboutComponent} from './about/about.component'
import {ContactComponent} from './contact/contact.component'
import {ProductsComponent} from './products/products.component'

// import { ElectronicsComponent} from './products/electronics/electronics.component';
// import {VideoGamesComponent} from './products/video-games/video-games.component';
// import {DvdsAndBluraysComponent} from './products/dvds-and-blurays/dvds-and-blurays.component';
// import {DjEquipmentComponent} from './products/dj-equipment/dj-equipment.component'
import { from } from 'rxjs';
import {BlogComponent} from './blog/blog.component'
import { LongupdateComponent } from './blog/longupdate/longupdate.component';
import { GeneralinfoComponent } from './blog/generalinfo/generalinfo.component';
import { Covid19updateComponent } from './blog/covid19update/covid19update.component';
import { WebsiteOpeningComponent } from './blog/website-opening/website-opening.component';
import { ElectronicsComponent } from './products/electronics/electronics.component';
import { VideoGamesComponent } from './products/video-games/video-games.component';
import { DjEquipmentComponent } from './products/dj-equipment/dj-equipment.component';
import { DvdsAndBluraysComponent } from './products/dvds-and-blurays/dvds-and-blurays.component';
// import {Covid19updateComponent} from './blog/covid19update/covid19update.component'
// import {WebsiteOpeningComponent} from './blog/website-opening/website-opening.component'
// import {GeneralInfoComponent} from './blog/general-info/general-info.component'
// import { LongupdateComponent } from './blog/longupdate/longupdate.component';
// import { UpdatesComponent } from './database/updates/updates.component';



const routes: Routes = [  
  {path: '', component: HomeComponent},
  {path: 'static', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'products', component: ProductsComponent},
  {path: 'blogs',component:BlogComponent},

  {path: 'electronics', component: ElectronicsComponent},
  {path: 'video-games', component: VideoGamesComponent},
  {path: 'dj-equipment', component: DjEquipmentComponent},
  {path: 'dvds-and-blurays', component: DvdsAndBluraysComponent},

  {path: 'covid19-update',component:Covid19updateComponent},
  {path: 'website-opening',component:WebsiteOpeningComponent},
  {path: 'general-info',component:GeneralinfoComponent},
  {path:'long-update',component:LongupdateComponent},

  // {path:'updates',component:UpdatesComponent},

  // {         
  //   path: 'ps2',         
  //   pathMatch: 'full',         
  //   component: Ps2Component
  // },
  // {         
  //   path: 'wii',         
  //   pathMatch: 'full',         
  //   component: WiiComponent
  // },
  // {         
  //   path: 'ps1',         
  //   pathMatch: 'full',         
  //   component: Ps1Component
  // },
  // {         
  //   path: 'gcn',         
  //   pathMatch: 'full',         
  //   component: GcnComponent
  // },
  // {         
  //   path: 'ds',         
  //   pathMatch: 'full',         
  //   component: DSComponent
  // },
  // {         
  //   path: 'segacd',         
  //   pathMatch: 'full',         
  //   component: SegacdComponent
  // },
  // {         
  //   path: 'segasaturn',         
  //   pathMatch: 'full',         
  //   component: SegasaturnComponent
  // },
  // {         
  //   path: 'n64',         
  //   pathMatch: 'full',         
  //   component: N64Component
  // },




]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
