import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-longupdate',
  templateUrl: './longupdate.component.html',
  styleUrls: ['./longupdate.component.css']
})
export class LongupdateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
