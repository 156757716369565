import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-games',
  templateUrl: './video-games.component.html',
  styleUrls: ['./video-games.component.css']
})
export class VideoGamesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
