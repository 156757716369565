import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { BlogComponent } from './blog/blog.component';
import { BottomNavigationComponent } from './bottom-navigation/bottom-navigation.component';
import { ContactComponent } from './contact/contact.component';
import { DatabaseComponent } from './database/database.component';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ProductsComponent } from './products/products.component';
import { SecondNavigationComponent } from './second-navigation/second-navigation.component';
import { AppRoutingModule } from './app-routing.module';
import { DjEquipmentComponent } from './products/dj-equipment/dj-equipment.component';
import { DvdsAndBluraysComponent } from './products/dvds-and-blurays/dvds-and-blurays.component';
import { ElectronicsComponent } from './products/electronics/electronics.component';
import { VideoGamesComponent } from './products/video-games/video-games.component';
import { Covid19updateComponent } from './blog/covid19update/covid19update.component';
import { GeneralinfoComponent } from './blog/generalinfo/generalinfo.component';
import { LongupdateComponent } from './blog/longupdate/longupdate.component';
import { WebsiteOpeningComponent } from './blog/website-opening/website-opening.component';
import { NgbdCarouselPause } from './home/carousel-pause/carousel-pause.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    BlogComponent,
    BottomNavigationComponent,
    ContactComponent,
    DatabaseComponent,
    HomeComponent,
    NavigationComponent,
    ProductsComponent,
    SecondNavigationComponent,
    DjEquipmentComponent,
    DvdsAndBluraysComponent,
    ElectronicsComponent,
    VideoGamesComponent,
    Covid19updateComponent,
    GeneralinfoComponent,
    LongupdateComponent,
    WebsiteOpeningComponent,
    NgbdCarouselPause
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
